<template>
  <b-overlay :show="isLoading" rounded="lg">
    <b-card title="Mass Delivery Order Update" sub-title="Updating unprocessed DO with Status Delivery in Progress to Fulfilled">
      <b-modal v-model="modalBulk" title="Bulk Update Delivery Orders">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to process mass update DO?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="bulkUpdate"
              >Confirm</b-button
            >
          </div>
        </template>
      </b-modal>
      <b-col cols="12">
        <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
        <b-row class="hide-on-mobile">
          <b-col md="6"> 
            <b-form-input
              v-model="search"
              type="search"
              placeholder="Search by DO"
              style="float: right; margin-top: 20px; margin-bottom: 30px;"
            />
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="9">
                <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  placeholder="Search by Delivery Date"
                  v-model="delivDate"
                  style="float: right; margin-top: 20px; margin-bottom: 30px;"
                />
              </b-col>
              <b-col md="2" cols="3">
                <b-button variant="danger" style="float: right; margin-top: 20px; margin-bottom: 30px;" @click="cleanQuery">X</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="hide-on-desktop">
          <b-col cols="12"> 
            <b-form-input
              v-model="search"
              type="search"
              placeholder="DO"
              style="float: right; margin-top: 20px;"
            />
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col cols="9">
                <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  placeholder="Delivery Date"
                  v-model="delivDate"
                  style="float: right; margin-top: 20px; margin-bottom: 30px;"
                />
              </b-col>
              <b-col md="2" cols="3">
                <b-button variant="danger" style="float: right; margin-top: 20px; margin-bottom: 30px;" @click="cleanQuery">X</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="mb-1">
            <b-button @click="doFiltering" variant="primary">search</b-button>
            <span style="color: red;"></span>
          </b-col>
          <b-col cols="6" class="mb-1 hide-on-desktop">
            <b-button v-if="permission.edit" @click="toBulkUpdate" variant="success">Mass Update</b-button>
            <span style="color: red;"></span>
          </b-col>
          <b-col cols="6" class="hide-on-mobile"></b-col>
        </b-row>
        <b-row>
          <b-col cols="6"></b-col>
          <b-col cols="3"></b-col>
          <b-col cols="3" class="mb-1 hide-on-mobile">
            <b-button v-if="permission.edit" @click="toBulkUpdate" variant="success">Mass Update</b-button>
            <span style="color: red;"></span>
          </b-col>
        </b-row>
        <!--END this is options for search data-->
        <b-row>
          <b-table
            responsive
            small
            hover
            bordered
            show-empty
            :items="filterDelivery"
            :fields="fields"
            :busy.sync="tableLoading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :stacked="stackedStatus"
          >
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPage - 1) * 10 }}
            </template>

            <template v-slot:cell(deliveryDate)="row">
              {{ dates(row.item.deliveryDate) }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                :dropleft="showDropdown"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    size="sm"
                    variant="info"
                    @click="toDetailPage(row.item)"
                    class="mr-1"
                  >
                    Detail
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(progress)="row">
              <b-progress :value="progressValue(row.item.status)" :max="100" animated></b-progress>
            </template>
          </b-table>
        </b-row>
        <b-row style="margin-top:10px">
          <b-col md="9" />
          <b-col md="3">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              align="fill"
              class="hide-on-desktop"
            />
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              class="hide-on-mobile"
              style="float: right"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat, sleep } from "@/utils/utils";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      //FIELD FOR MOBILE
      btnshow: true,
      showDropdown: true,
      btnBackMobile: "hide-at-all",
      conditionView: "ml-md-auto hide-on-mobile",
      stackedStatus: "md",
      //sort direction list for mobile view
      directions: [
        { key: false, label: "Asc", sortable: true },
        { key: true, label: "Desc", sortable: true },
      ],
      //END FIELD FOR MOBILE
      fields: [
        { key: "index", label: "No" },
        { key: "sapSo", label: "SAP SO", filterable: true, sortable: true },
        { key: "sapDo", label: "SAP DO", filterable: true, sortable: true },
        {
          key: "deliveryDate",
          label: "Delivery Date",
          filterable: true,
          sortable: true,
        },
        { key: "status", label: "STATUS", filterable: true, sortable: true },
        // { key: "progress", label: "Progress", filterable: true, sortable: true },
        { key: "actions", label: "Actions" },
      ],
      shippingPoint:"1001",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      tableLoading: true,
      isLoading: false, //for loading
      sortBy: "", //this for mobile view
      sortDesc: false, //this for mobile view
      showSortBy: false, //this for mobile view
      search: "",
      selectData: "",
      modalDeliver: false,
      modalBulk: false,
      listStatus: [
        "New",
        "Edit Delivery",
        "Edit in Progress",
        "Cancel Delivery",
        "Delivery Canceled",
        "Scanning in Progress",
        "Scanning Completed",
        "Delivery in Progress",
        "Fulfilled",
        "Collected",
        "Returned",
        "Waiting Acknowledgment",
        "Waiting Approval",
        "Approved",
        "Return Completed",
        "Re-Delivery",
        "All",
      ],
      defaultStatus:"Scanning in Progress",
      deliveryStatus: "",

      delivDate: "", //model for search data by delivery date
    };
  },
  computed: {
    filterDelivery() {
      var result = this.deliveryOrders;
      console.log('result', result);
      return result;
    },
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"]
      return tempDO;
    },
    permission() {
      let result = userAccess("Mass DO Update", "utilitytools_menu");
      // console.log("Delivery Orders Sumitomo",result)
      return result;
    },
    // Create an options list from our fields
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    // Create an direction list that can be saved in session
    directionOptions() {
      return this.directions
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  created() {
    document.title = "Mass DO Update | RSP";
  },
  mounted() {
    // this.$store.dispatch("delivery/getDeliveryOrder");

    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageDeliveryOrdersDaikin")) {
      this.perPage = this.$session.get("perPageDeliveryOrdersDaikin");
    }
    if (this.$session.has("sortByDeliveryOrdersDaikin")) {
      this.sortBy = this.$session.get("sortByDeliveryOrdersDaikin");
    }
    if (this.$session.has("sortDescDeliveryOrdersDaikin")) {
      this.sortDesc = this.$session.get("sortDescDeliveryOrdersDaikin");
    }

    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    var doTemp;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
      this.doFiltering()
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return;
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = this.defaultStatus;
    }
    this.queryDelivery()
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageDeliveryOrdersDaikin", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByDeliveryOrdersDaikin", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescDeliveryOrdersDaikin", sortDescNew);
    },
    // stackedStatus(stackedStatusNew) {
    //   this.$session.set("stackedStatusWarehouseDistribution", stackedStatusNew)
    // }
  },
  methods: {
    ...mapActions({
      getMassDoUpdateList: "delivery/getMassDoUpdateList",
      updateMassDo: "delivery/updateMassDo"
    }),
    onPageChange(page) {
      this.currentPage = page;
      this.queryDelivery();
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    dates(date) {
      return dateFormat(date);
    },
    queryDelivery() {

      this.getMassDoUpdateList({
        page: this.currentPage,
        entry: this.perPage,
        sapDo: this.search,
        delivDate: this.delivDate,
      }).then((data) => {
        this.processMetadata(data.metadata)
        this.isLoading = false
      }).catch((e) => {
        this.$bvToast.toast("Load data failed, please reload the page.", {
        title: "Warning",
        variant: "danger",
        solid: true,
        });
        console.log(e);
      });
    },
    cleanQuery() {
      this.delivDate = "";
      this.currentPage = 1;
      this.queryDelivery();
    },
    doFiltering() {
      this.isLoading = true
      //this.search = value;
      this.currentPage = 1;
      this.queryDelivery();
    },
    toBulkUpdate(){
      this.modalBulk = true
    },
    bulkUpdate() {
      this.isLoading = true
      let body = {
        sapDo : this.search,
        delivDate : this.delivDate
      }
      this.updateMassDo({data : body}).then(() => {
          this.$bvToast.toast("Bulk Update Success", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.isLoading = false
          this.modalBulk = false
          this.queryDelivery()
        })
        .catch((e) => {
          this.isLoading = false
          this.modalBulk = false
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    fireFiltering(value) {
      this.delivDate = value;
      //this is for datepicker load store data with query
      this.currentPage = 1;
      this.queryDelivery();
    },
    toDetailPage(data) {
      let idDO = data._id;
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: idDO, from: "mass-do-update",status:this.deliveryStatus },
      });
    },
    progressValue(status) {
      if (status === 'New') {
        return 0;
      } else if (status === 'Edit Delivery ' || status === 'Edit in Progress' || status === 'Cancel Delivery' || status === 'Delivery Canceled' || status === 'Scanning in Progress' || status === 'Scanning Completed') {
        return 33;
      } else if (status === 'Delivery in Progress') {
        return 66;
      } else if (status === 'Fulfilled' || status === 'Collected' || status === 'Returned' || status === 'Waiting Acknowledgment' || status === 'Waiting approval' || status === 'Approved' || status === 'Return Completed') {
        return 100;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
